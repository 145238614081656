import { useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';

/** Apple Signin button */
const MyAppleSigninButton = () => {
  const [clientId, setClientId] = useState('com.example.web');
  const [redirectURI, setRedirectURI] = useState('https://example.com');
  return (
    <div>
      <AppleSignin
        /** Auth options passed to AppleID.auth.init() */
        authOptions={{
          clientId: clientId,
          scope: 'email name',
          redirectURI: 'https://example.com',
          state: '',
          nonce: 'nonce',
          usePopup: true,
        }}
        uiType="dark"
        className="apple-auth-btn"
      />

      <div>
        <label>Apple clientId: </label>
        <input onChange={ (e) => setClientId(e.target.value) } value={ clientId } />
      </div>
      <div>
        <label>Apple redirectURI: </label>
        <input onChange={ (e) => setRedirectURI(e.target.value) } value={ redirectURI } />
      </div>
    </div>
  );
};

export default MyAppleSigninButton;
