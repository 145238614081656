import AppleAuthButton from './AppleAuthButton';
import GoogleAuthButton from './GoogleAuthButton';

const SocialAuthButtons = () => {
    return (
        <div>
            <AppleAuthButton />
            <GoogleAuthButton />
        </div>
    )
};

export default SocialAuthButtons;
