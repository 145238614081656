import './App.css';
import SocialAuthButtons from './components/SocialAuthButtons';

function App() {
  return (
    <div className="App">
      WHOOP Login Portal
      <SocialAuthButtons />
    </div>
  );
}

export default App;
