import GoogleLogin from 'react-google-login';
import { useState } from 'react';

const onResponse = (response) => {
  console.log(response);
};

const GoogleAuthButton = () => {
  const [clientId, setClientId] = useState('800472043534-mlhbpgla8j4ceqa53vt1eahsbjh9tchs.apps.googleusercontent.com');
  return (
    <div>
      <GoogleLogin
        clientId={ clientId }
        buttonText="Login"
        onSuccess={onResponse}
        onFailure={onResponse}
        cookiePolicy={'single_host_origin'}
      />
      <div>
        <label>
          Google clientId:
        </label>
        <input onChange={ (e) => setClientId(e.target.value) } value={ clientId } />
      </div>
    </div>
  );
};

export default GoogleAuthButton;
